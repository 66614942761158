<template>
    <v-data-iterator
        :items-per-page.sync="itemsPerPage"
        :footer-props="{
            itemsPerPageOptions: itemsPerPageOptions
        }"
        :items="filteredTables"
    >
        <template v-slot:header>
            <v-row no-gutters justify="end">
                <v-col cols="6">
                    <v-text-field label="Filter tables" append-icon="search" v-model="tableSearch" single-line dense> </v-text-field>
                </v-col>
            </v-row>
        </template>
        <template v-slot:default="props">
            <v-list two-line>
                <div v-for="(item, index) in props.items" :key="index">
                    <v-list-item :key="item.tid">
                        <v-list-item-avatar>
                            <v-icon large>mdi-table-large</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                <v-menu
                                    :value="tableActionsMenus[item.tid]"
                                    left
                                    origin="center center"
                                    transition="scale-transition"
                                    max-width="30%"
                                    nudge-bottom="30"
                                >
                                    <template v-slot:activator="{ on: menu }">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on: tooltip }">
                                                <a v-on="{ ...tooltip, ...menu }" class="secondary--text subtitle-2">
                                                    {{ item.short_id }}
                                                </a>
                                            </template>
                                            <span>{{ item.short_id }}</span>
                                        </v-tooltip>
                                    </template>
                                    <v-list style="overflow-y: hidden;" nav dense>
                                        <QueryBuilderDialog
                                            :tableId="parseInt(item.tid, 10)"
                                            :tableName="item.short_id"
                                            @querySubmitSuccess="$emit('switchTab', { tab: 5 })"
                                            @dialogClosed="setTableActionMenuValue(item.tid, false)"
                                            @dialogOpened="setTableActionMenuValue(item.tid, true)"
                                            :listItem="true"
                                        />
                                        <v-list-item @click="exploreTable(item.short_id, item.tid, 'columnList')">
                                            <v-list-item-title>
                                                <v-icon class="mr-1" small>zoom_in</v-icon>
                                                Explore columns
                                            </v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click="exploreTable(item.short_id, item.tid, 'dataPreview')">
                                            <v-list-item-title>
                                                <v-icon class="mr-1" small>mdi-playlist-play</v-icon>
                                                Preview table
                                            </v-list-item-title>
                                        </v-list-item>
                                        <CopyToClipboard
                                            :textToCopy="quotedName(item.short_id)"
                                            buttonName="Copy name to clipboard"
                                            type="listItem"
                                        ></CopyToClipboard>
                                    </v-list>
                                </v-menu>
                            </v-list-item-title>
                            <v-list-item-subtitle class="text--primary">
                                <div class="d-flex caption">
                                    <span class="font-weight-bold primary--text mr-1">Size:</span>
                                    {{ humanFileSize(item.bytes, true) }}
                                    <span class="mx-2">|</span>
                                    <span class="font-weight-bold primary--text mr-1">Rows: </span>
                                    <span class="caption">{{ item.row_count.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }}</span>
                                </div>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                                <v-tooltip max-width="700" v-if="item.description" bottom>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">{{ item.description }}</span>
                                    </template>
                                    <span>{{ item.description }}</span>
                                </v-tooltip>
                                <span v-else>No description available.</span>
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <div class="d-flex align-center">
                                <QueryBuilderDialog
                                    :tableId="parseInt(item.tid, 10)"
                                    :tableName="item.short_id"
                                    @querySubmitSuccess="$emit('switchTab', { tab: 5 })"
                                />
                                <v-menu :close-on-content-click="false" left offset-x>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" text color="primary" small>
                                            <v-icon>more_horiz</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-text>
                                            <div class="d-flex align-start flex-column">
                                                <v-btn @click="addToStaging([item])" class="white--text" color="primary" text>
                                                    <v-icon class="mr-1" small>share</v-icon>stage
                                                </v-btn>
                                                <RenameButton
                                                    :selected="[item]"
                                                    objectType="table"
                                                    :dispatchFunction="
                                                        () => {
                                                            $store.dispatch('snapshotStore/fetchCurrentTables', $route.params.snid)
                                                        }
                                                    "
                                                    v-if="isDevelopment === true"
                                                ></RenameButton>
                                                <DeleteButton
                                                    :selected="[item]"
                                                    objectType="tables"
                                                    :dispatchFunction="
                                                        () => {
                                                            $store.dispatch('snapshotStore/fetchCurrentTables', $route.params.snid)
                                                        }
                                                    "
                                                    v-if="isDevelopment === true"
                                                ></DeleteButton>
                                                <EditDescriptionButton
                                                    :selected="[item]"
                                                    objectType="table"
                                                    :dispatchFunction="
                                                        () => {
                                                            $store.dispatch('snapshotStore/fetchCurrentTables', $route.params.snid)
                                                        }
                                                    "
                                                    v-if="isDevelopment === true"
                                                ></EditDescriptionButton>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </div>
                        </v-list-item-action>
                    </v-list-item>
                    <v-divider inset :key="index"></v-divider>
                </div>
            </v-list>
        </template>
    </v-data-iterator>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { truncateText, humanFileSize } from '@/utils'
const QueryBuilderDialog = () => import('../components/TheSnapshotQueryBuilderDialog')
const EditDescriptionButton = () => import('../components/TheSnapshotTableColDescriptionEdit')
const RenameButton = () => import('../components/TheSnapshotRenameButton')
const DeleteButton = () => import('../components/TheSnapshotDeleteButton')
const CopyToClipboard = () => import('@/components/CopyToClipboard')

export default {
    name: 'TableListIterator',
    components: { QueryBuilderDialog, EditDescriptionButton, RenameButton, DeleteButton, CopyToClipboard },
    data() {
        return {
            tableSearch: '',
            itemsPerPage: 25,
            itemsPerPageOptions: [25, 50, 100, -1],
            tableActionsMenus: [],
            currentTableSelected: ''
        }
    },
    computed: {
        ...mapState('snapshotStore', ['currentTables', 'stagingObjects', 'tablesFetching']),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        filteredTables() {
            if (this.currentTables && this.$data.tableSearch) {
                return this.currentTables.filter(table => table.short_id.toLowerCase().includes(this.$data.tableSearch.toLowerCase()))
            }
            return this.currentTables
        }
    },
    methods: {
        truncateText: function(text, maxLength) {
            return truncateText(text, maxLength)
        },
        setTableActionMenuValue(tid, status) {
            this.$data.tableActionsMenus[tid] = status
        },
        exploreTable(tableName, tid, target) {
            if (this.$data.currentTableSelected !== tableName) {
                this.$data.currentTableSelected = tableName
                this.$emit('newTableSelected', { table: tableName })
                this.$store.dispatch('snapshotStore/fetchColumnAndPreviewData', tid)
            }
            if (target === 'columnList') {
                this.$data.tabView = 1
                this.$emit('switchTab', { tab: 1 })
            } else if (target === 'dataPreview') {
                this.$emit('switchTab', { tab: 2 })
            }
        },
        quotedName: function(name) {
            return '"' + name + '"'
        },
        humanFileSize: function(bytes, si) {
            return humanFileSize(bytes, si)
        },
        addToStaging: function(data) {
            if (data.length > 0) {
                const stagingObjectsBefore = this.stagingObjects.tables.length
                var tables = []
                var tableOrder = data.length
                data.forEach(table => {
                    var tableObject = table
                    tableObject.filePath = table.short_id
                    tableObject.fileType = 'table'
                    tables.push(tableObject)
                    tableOrder = tableOrder - 1
                    if (tableOrder === 0) {
                        this.$store.dispatch('snapshotStore/updateStagingObjects', {
                            itemType: 'table',
                            newItems: tables,
                            updateMode: 'add'
                        })
                    }
                })
                const stagingObjectsAfter = this.stagingObjects.tables.length
                if (stagingObjectsAfter !== stagingObjectsBefore) {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Table(s) added to stage.',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                } else {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Selected table(s) already staged.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'info'
                    })
                }
            }
        }
    },
    mounted() {
        if (this.currentTables) {
            const tableMenus = {}
            this.currentTables.forEach(table => {
                tableMenus[table.tid] = false
            })
            this.$data.tableActionsMenus = tableMenus
        }
    },
    watch: {
        currentTables: function(nextVal) {
            if (nextVal) {
                const tableMenus = {}
                nextVal.forEach(table => {
                    tableMenus[table.tid] = false
                })
                this.$data.tableActionsMenus = tableMenus
            }
        }
    }
}
</script>
